import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Scorpion1 from '../images/cup-nov-24-scorpion1.jpg';
import Scorpion2 from '../images/cup-nov-24-scorpion2.jpg';
import Scorpion3 from '../images/cup-nov-24-scorpion3.jpg';
import Scorpion4 from '../images/cup-nov-24-scorpion4.jpg';
import Scorpion5 from '../images/cup-nov-24-scorpion5.jpg';
import Scorpion6 from '../images/cup-nov-24-scorpion6.jpg';
import Scorpion7 from '../images/cup-nov-24-scorpion7.jpg';
import Scorpion8 from '../images/cup-nov-24-scorpion8.jpg';
import Scorpion9 from '../images/cup-nov-24-scorpion9.jpg';
import Scorpion10 from '../images/cup-nov-24-scorpion10.jpg';
import Scorpion11 from '../images/cup-nov-24-scorpion11.jpg';
import Scorpion12 from '../images/cup-nov-24-scorpion12.jpg';
import Scorpion13 from '../images/cup-nov-24-scorpion13.jpg';
import Scorpion14 from '../images/cup-nov-24-scorpion14.jpg';
import Scorpion15 from '../images/cup-nov-24-scorpion15.jpg';
import Scorpion16 from '../images/cup-nov-24-scorpion16.jpg';

const CupNov24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Scorpion</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                      <p>For the Scorpio star sign, I made a 3d glow in the dark / UV scorpion using 3D Origami.</p>
                      <div className="gallery">
                        <div className="imgThird"><img src={Scorpion1} alt="Painting paper with UV Acrylic paint" /></div>
                        <div className="imgThird"><img src={Scorpion2} alt="Making the first folds for origami modules" /></div>
                        <div className="imgThird"><img src={Scorpion3} alt="Paper cut into squares ready for origami folding" /></div>
                        <div className="imgThird"><img src={Scorpion4} alt="Folded 3d origami modules" /></div>
                        <div className="imgThird"><img src={Scorpion5} alt="Folded 3d origami modules" /></div>
                        <div className="imgThird"><img src={Scorpion6} alt="3d origami modules put together, starting to form a scorpion" /></div>
                        <div className="imgThird"><img src={Scorpion7} alt="3d origami modules put together, starting to form a scorpion" /></div>
                        <div className="imgThird"><img src={Scorpion8} alt="A Scorpion tail put together from 3d origami modules" /></div>
                        <div className="imgThird"><img src={Scorpion9} alt="A scorpion body and tail put together from 3d origami modules" /></div>
                        <div className="imgThird"><img src={Scorpion10} alt="Scorpion legs put together from 3d origami modules" /></div>
                        <div className="imgThird"><img src={Scorpion11} alt="Scorpion body, legs and tail put together from 3d origami modules" /></div>
                        <div className="imgThird"><img src={Scorpion12} alt="3d origami scorpion with pincers" /></div>
                        <div className="imgThird"><img src={Scorpion13} alt="Scorpion legs made from 3d origami modules" /></div>
                        <div className="imgThird"><img src={Scorpion14} alt="3d Origami Scorpion in natural light" /></div>
                        <div className="imgThird"><img src={Scorpion15} alt="3d Origami Scorpion in UV light" /></div>
                        <div className="imgThird"><img src={Scorpion16} alt="3d Origami Scorpion glowing in the dark" /></div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CupNov24