import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const AdvancedDiarist = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Advanced Diarist"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 1</div>
                <div className="badgePageItemInfo green-light">Keep a Diary</div>
                <div className="badgePageItemInfo hidden">Over 6 months try keeping a different type of journal each month. Choose your favourite then record in that for the next 6 mths</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default AdvancedDiarist