import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Carpenter = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Carpenter"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 1</div>
                <div className="badgePageItemInfo purple-light">Tools</div>
                <div className="badgePageItemInfo hidden">Identify hand and machine tools which can be found in a carpenter's workshop and know what each is used for</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 2</div>
                <div className="badgePageItemInfo purple-light">Maintenance</div>
                <div className="badgePageItemInfo hidden">Learn how to clean,maintain, use and safely store key carpentry tools</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 3</div>
                <div className="badgePageItemInfo purple-light">Joints</div>
                <div className="badgePageItemInfo hidden">Learn about three types of joint and draw a diagram of each one. Use at least one for the projects.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 4</div>
                <div className="badgePageItemInfo purple-light">Wood</div>
                <div className="badgePageItemInfo hidden">Learn about the difference between green woodwork and working with seasoned wood. Understand safe practices for reusing wood.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 5</div>
                <div className="badgePageItemInfo purple-light">Finishing</div>
                <div className="badgePageItemInfo hidden">Learn how to finish projects - eg sanding, planing, staining, varnishing, food safe finishes. Use at least two in projects.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Project 1</div>
                <div className="badgePageItemInfo purple-light">Picture Frame</div>
                <div className="badgePageItemInfo hidden">Make a Picture Frame</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>    
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Project 2</div>
                <div className="badgePageItemInfo purple-light">TBC</div>
                <div className="badgePageItemInfo hidden">Box with hinged lid, something useful from old pallets, Something that can be used in a garden.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>    
        </div>
    )
}

export default Carpenter