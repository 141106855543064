import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Bambi from '../images/Palaeontology.jpg';
import Model1 from '../images/palaeontology-dinomodel1.jpg';
import Model2 from '../images/palaeontology-dinomodel2.jpg';
import Model3 from '../images/palaeontology-dinomodel3.jpg';
import Model4 from '../images/palaeontology-dinomodel4.jpg';
import Craft1 from '../images/palaeontology-dinocraft1.jpg';
import Fossil1 from '../images/palaeontologist1.jpg';
import Fossil2 from '../images/palaeontologist2.jpg';
import Fossil3 from '../images/palaeontologist3.jpg';
import Fossil4 from '../images/palaeontologist4.jpg';
import Fossil5 from '../images/palaeontologist5.jpg';
import Fossil6 from '../images/palaeontologist6.jpg';
import Fossil7 from '../images/palaeontologist7.jpg';
import Fossil8 from '../images/palaeontologist8.jpg';
import Fossil9 from '../images/palaeontologist9.jpg';
import Fossil10 from '../images/palaeontologist10.jpg';

const Paleo = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Palaeontologist"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>The name “mesozoic” is derived from the Greek term for “middle life” and it is one of three major geologic eras in Earth's Phanerozoic Eon. It was generally warm, with less temperature variation than today and is best known as the time of the dinasaurs. Major plant and animal groups that exist today first appeared during this era.</p>
                        <br/>
                        <p>It started approx. 252.2 million years ago, at the end of the Paleozoic Era.</p>
                        <br/>
                        <p>It ended approx. 66 million years ago, starting the Cenozoic Era.</p>
                        <br/>
                        <p>The three mesozoic eras are:</p>
                        <ul className='list'>
                            <li>Triassic Period</li>
                            <li>Jurassic Period</li>
                            <li>Cretaceous Period</li>
                        </ul>
                        <br/>
                        <p><strong>Triassic</strong></p>
                        <p>The Triassic was a time of recovery, innovation, and the dawn of remarkable creatures that would shape Earth's future</p>
                        <br/>
                        <ol className='list'>
                            <li>Duration and Boundaries
                                <ul className='list'>
                                    <li>Spanned approx. 50.5 million years</li>
                                    <li>Started at the end of the Permian Period</li>
                                    <li>Ended at the start of the Jurassic Period</li>
                                    <li>First and shortest period in the Mesozoic Era</li>
                                </ul>
                            </li>
                            <li>Major Extinction Events
                                <ul className='list'>
                                    <li>Both the beginning and evd of the Triassic period were marked by significant extinction events</li>
                                    <li>The Permian-Triassic extinction event (the most severe mass extinction in Earth's history) led to the loss of many species.</li>
                                    <li>During the middle of this period, life gradually recovered its diversity</li>
                                </ul>
                            </li>
                            <li>Epochs
                                <ul className='list'>
                                    <li>Divided into 3 epochs:
                                        <ul className='list'>
                                            <li>Early - recovery from mass extinction</li>
                                            <li>Middle - emergence of new organisms</li>
                                            <li>Late - further diversification and rise of the archosaurs</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>Life Forms
                                <ul className='list'>
                                    <li>Survivors - some survivors of the Permian extinction</li>
                                    <li>New Groups - Briefly flourishing groups appeared, while others went on to dominate the Mesozoic Era.</li>
                                    <li>Reptiles - Archosaurs (including dinosaurs) were the dominant terrestrial vertebrates.</li>
                                    <li>Dinosaurs - Appeared in late Triassic, not dominant until the Jurassic period</li>
                                    <li>Pterosaurs - Flying reptiles evolved during this time</li>
                                    <li>Therapsids - Dominant vertibrates of the Permian, declined</li>
                                    <li>First True Mammals - Subgroup of Therapsids, ancestors of modern mammals, evolved during this time</li>
                                </ul>
                            </li>
                            <li>Supercontinent Pangaea
                                <ul className='list'>
                                    <li>The vast supercontinent Pangaea dominated the globe.</li>
                                </ul>
                            </li>
                        </ol>
                        <br/><br/>
                        <p><strong>Jurassic</strong></p>
                        <p>The Jurassic was a time of climatic diversity, the rise of dinosaurs, and the emergence of early avian life.</p>
                        <br/>
                        <ol className='list'>
                            <li>Duration and Boundaries
                                <ul className='list'>
                                    <li>Spanned approx. 201.4 million years</li>
                                    <li>Started at the end of the Triassic period</li>
                                    <li>Ended at the start of the Cretaceous period</li>
                                </ul>
                            </li>
                            <li>Geological Context
                                <ul className='list'>
                                    <li>The name sis derived from the Jura Mountains where limestone strata from this period were first identified</li>
                                </ul>
                            </li>
                            <li>Key Events
                                <ul className='list'>
                                    <li>Triassic-Jurassic Extinction - this event started this period, it is associated with the eruption of the Central Atlantic Magmatic Province (CAMP) and led to the demise of various species</li>
                                    <li>Toarcian Oceanic Anoxic Event - global episode of oceanic anoxia, ocean acidification and eleveted temperatures. Likely caused by the eruption of the Karoo-Ferrar large igneous provinces</li>
                                    <li>Lacks a clear end boundary, the only geological period boundary that remains formally undefined</li>
                                </ul>
                            </li>
                            <li>Paleoclimate
                                <ul className='list'>
                                    <li>Warmer than today with no ice caps</li>
                                    <li>Forests thrived even close to the pole, arid regions existed in lower latitudes</li>
                                </ul>
                            </li>
                            <li>Life forms
                                <ul className='list'>
                                    <li>Dinosaurs - dominated this period</li>
                                    <li>First Birds - evolved from theropod dinosaurs</li>
                                    <li>Appearance of earliest crabs and modern lizards</li>
                                    <li>Diversification of early mammals</li>
                                    <li>Crocodylomorphs transitioned from terrestrial to aquatic life</li>
                                </ul>
                            </li>
                            <li>Supercontinent Pangaea
                                <ul className='list'>
                                    <li>At the beginning of this period, the supercontinent Pangaea started to split into two:
                                        <ul className='list'>
                                            <li>Laurasia (North)</li>
                                            <li>Gondwana (South)</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ol>
                        <br/><br/>
                        <p><strong>Cretacious</strong></p>
                        <p>The Cretaceous was a period of dynamic change, marked by flourishing life forms, climatic shifts, and the dramatic end of the dinosaurs.</p>
                        <br/>
                        <ol className='list'>
                            <li>Duration and Boundaries
                                <ul className='list'>
                                    <li>Lasted from approx 145 to 66 million years ago</li>
                                    <li>Longest Mesozoic era</li>
                                    <li>Longest geological period of the entire Phanerozoic, 79 million years</li>
                                    <li>The name is derived from the Latin word “creta” which means “chalk”. Chalk was abundant in the later half of the period.</li>
                                </ul>
                            </li>
                            <li>Climate and Sea Levels
                                <ul className='list'>
                                    <li>Warm climate</li>
                                    <li>High eustatic sea levels created numerous shallow inland seas</li>
                                    <li>Oceans and seas populated with marine reptiles, ammonites and rudists</li>
                                    <li>Dinosaurs continued to dominate</li>
                                    <li>Forests extended to the poles</li>
                                </ul>
                            </li>
                            <li>Flowering Plants and Diversification
                                <ul className='list'>
                                    <li>Flowering plants (angiosperms) appeared and rapidly diversified, becoming the dominant group of plants across the Earth</li>
                                    <li>Previously widespread gymnosperm group declined and faced extinction</li>
                                </ul>
                            </li>
                            <li>Life forms
                                <ul className='list'>
                                    <li>New groups of mammals and birds appeared</li>
                                    <li>Mammals diversified and adapted to various ecological niches</li>
                                </ul>
                            </li>
                            <li>Mass Extinction and the K-Pg Boundary
                                <ul className='list'>
                                    <li>The Cretaceous ended with the Cretaceous-Paleogene extinction event (K-Pg boundary).</li>
                                    <li>This let to the demise of many groups, including non-avian dinosaurs, pterosaurs and large marine reptiles</li>
                                    <li>Thought to have been caused by the impact of a large asteroid that formed the Chicxulub crater in the Gulf of Mexico</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <table>
                            <thead>
                                <tr>
                                    <th>Dinosaur</th>
                                    <th>Era</th>
                                    <th>Diet</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Aardonyx</td>
                                    <td>Triassic</td>
                                    <td>Herbivore</td>
                                </tr>
                                <tr>
                                    <td>Abelisaurus</td>
                                    <td>Cretaceous</td>
                                    <td>Carnivore</td>
                                </tr>
                                <tr>
                                    <td>Abrictosaurus</td>
                                    <td>Jurassic</td>
                                    <td>Herbivore</td>
                                </tr>
                                <tr>
                                    <td>Acrocanthosaurus</td>
                                    <td>Early Cretaceous</td>
                                    <td>Carnivore</td>
                                </tr>
                                <tr>
                                    <td>Adamantisaurus</td>
                                    <td>Cretaceous</td>
                                    <td>Herbivore</td>
                                </tr>
                                <tr>
                                    <td>Adasaurus</td>
                                    <td>Cretaceous</td>
                                    <td>Carnivore</td>
                                </tr>
                                <tr>
                                    <td>Aegyptosaurus</td>
                                    <td>Cretaceous</td>
                                    <td>Herbivore</td>
                                </tr>
                                <tr>
                                    <td>Aerosteon</td>
                                    <td>Cretaceous</td>
                                    <td>Carnivore</td>
                                </tr>
                                <tr>
                                    <td>Afrovenator</td>
                                    <td>Cretaceous</td>
                                    <td>Carnivore</td>
                                </tr>
                                <tr>
                                    <td>Agathaumas</td>
                                    <td>Cretaceous</td>
                                    <td>Herbivore</td>
                                </tr>
                                <tr>
                                    <td>Agilisaurus</td>
                                    <td>Jurassic</td>
                                    <td>Herbivore</td>
                                </tr>
                                <tr>
                                    <td>Alamosaurus</td>
                                    <td>Cretaceous</td>
                                    <td>Herbivore</td>
                                </tr>
                                <tr>
                                    <td>Alaskacephale</td>
                                    <td>Late Cretaceous</td>
                                    <td>Herbivore</td>
                                </tr>
                                <tr>
                                    <td>Argentinosaurus</td>
                                    <td>Late Cretaceous</td>
                                    <td>Herbivore</td>
                                </tr>
                                <tr>
                                    <td>Bambiraptor</td>
                                    <td>Cretaceous</td>
                                    <td>Carnivore</td>
                                </tr>
                                <tr>
                                    <td>Brachiosaurus</td>
                                    <td>Jurassic</td>
                                    <td>Herbivore</td>
                                </tr>
                                <tr>
                                    <td>Dromiceiomimus</td>
                                    <td>Cretaceous</td>
                                    <td>Omnivore</td>
                                </tr>
                                <tr>
                                    <td>Edmontosaurus</td>
                                    <td>Cretaceous</td>
                                    <td>Herbivore</td>
                                </tr>
                                <tr>
                                    <td>Nomingia</td>
                                    <td>Cretaceous</td>
                                    <td>Herbivore</td>
                                </tr>
                                <tr>
                                    <td>Triceratops</td>
                                    <td>Late Cretaceous</td>
                                    <td>Herbivore</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I have chosen Bambiraptor for this clause.</p>    
                        <br/>
                        <img src={Bambi} alt="Factsheet about the Dinosaur called bambiraptor" className="imgHalf" />
                        <br/><br/>
                        <p>Source: https://www.nhm.ac.uk/discover/dino-directory/bambiraptor.html</p>
                        <p>Source: https://en.wikipedia.org/wiki/Bambiraptor</p>
                        <p>Source: https://fossil.fandom.com/wiki/Bambiraptor</p>
                        <p>Source: https://dinosaurpictures.org/Bambiraptor-pictures </p>
                        <br/>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Here is a cute dino as part of the wreath that I made.</p>
                        <img className="imgThurd" src={Craft1} alt="Little wooden dinosaur included in a dried flower wreath"/> 
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                    <p>For this clause, I went fossil hunting in the Lyme Bay area whilst glamping for my Silver Maverick! At first, we used just rocks to tap for fossils, but ended up getting a small fossil hammer. I bought a book which explained how to fossil hunt safely, where to go and what to use.</p>
                    <p>Here are a few photos of the best finds. I was super excited to get my little crinoid stars!</p>
                    <div className="gallery">
                        <div className="galleryItem"><img src={Fossil1} alt="A pocket book titled Finding Fossils in Lyme Bay" /></div>
                        <div className="galleryItem"><img src={Fossil2} alt="A view of West Bay standing on the beach" /></div>
                        <div className="galleryItem"><img src={Fossil3} alt="An ammonite fossil" /></div>
                        <div className="galleryItem"><img src={Fossil4} alt="A fossil in a rock" /></div>
                        <div className="galleryItem"><img src={Fossil5} alt="A fossil in a rock" /></div>
                        <div className="galleryItem"><img src={Fossil6} alt="A fossil in a rock" /></div>
                        <div className="galleryItem"><img src={Fossil7} alt="A fossil in a rock" /></div>
                        <div className="galleryItem"><img src={Fossil8} alt="A fossil in a rock" /></div>
                        <div className="galleryItem"><img src={Fossil9} alt="A fossil in a rock" /></div>
                        <div className="galleryItem"><img src={Fossil10} alt="Crinoid Starts fossils being held in a hand" /></div>
                    </div>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I made a mini triceratops using air dry clay. I then made it a friend using the left-over clay!</p>
                        <div className="gallery">
                            <div className="galleryItem" src={Model1} alt="Packet of air dry clay and base model of a dinosaur to model on." />
                            <div className="galleryItem" src={Model2} alt="Contents of the air dry clay packet, two pouches of clay and a dinosaur skeleton" />
                            <div className="galleryItem" src={Model3} alt="Completed model dinpsaur" />
                            <div className="galleryItem" src={Model4} alt="Completed model dinpsaur .. plus one!" />
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Paleo