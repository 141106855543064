import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Forager = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Forager"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Poisonous Mushrooms</div>
                    <div className="badgePageItemInfo hidden">Learn to identify common poisonous mushrooms and poisonous berries in your local area</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Calendar</div>
                    <div className="badgePageItemInfo hidden">Make a foraging calendar listing what can be foraged in your area and when. Make a map of local places you can forage.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Water</div>
                    <div className="badgePageItemInfo hidden">Research ways to filter or find clean water in the wild so it can be drunk</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Course</div>
                    <div className="badgePageItemInfo hidden">Attend a foraging course or guided experience and make something with ingredients foraged during the course.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Identify</div>
                    <div className="badgePageItemInfo hidden">Learn to identify at least 10 plants in your area which can be eaten, and anything they are easily confused with</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Rules and Responsibility</div>
                    <div className="badgePageItemInfo hidden">Learn about responsible foraging and any rules or restrictions which apply to foraging in your local area</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 7</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Damage</div>
                    <div className="badgePageItemInfo hidden">Learn about the damage which can be caused by irresponsible foraging in sensitive environments.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>    
        </div>
    )
}

export default Forager