import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const AdvancedGoals = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Advanced Goals"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Goal 1</div>
                <div className="badgePageItemInfo pink-light">Health</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Goal 2</div>
                <div className="badgePageItemInfo pink-light">Wealth</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Goal 3</div>
                <div className="badgePageItemInfo pink-light">Travel</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Goal 4</div>
                <div className="badgePageItemInfo pink-light">Fitness</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Goal 5</div>
                <div className="badgePageItemInfo pink-light">Creativity</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Goal 6</div>
                <div className="badgePageItemInfo pink-light">Knowledge</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default AdvancedGoals