import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Scrapbooker = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Scrapbooker"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Task 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Create a scrapbook</div>
                    <div className="badgePageItemInfo hidden">Create a paper scrapbook with at least twenty-five pages to record an event or as a gift for someone. 
                    Use a variety of different methods of decoration across the book.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>   
        </div>
    )
}

export default Scrapbooker