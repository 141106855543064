import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const MyChallenge = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"My Challenge"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Clause 1</div>
                <div className="badgePageItemInfo pink-light">Challenge</div>
                <div className="badgePageItemInfo hidden">Set yourself a personal landmark challenge to complete.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default MyChallenge