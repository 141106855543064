import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const LocalHistory = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Local History"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 1</div>
                <div className="badgePageItemInfo blue-light">Museum</div>
                <div className="badgePageItemInfo hidden">Visit a local museum to learn more about the history of your local area</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 2</div>
                <div className="badgePageItemInfo blue-light">Make</div>
                <div className="badgePageItemInfo hidden">Make a Traditional local craft or bake</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 3</div>
                <div className="badgePageItemInfo blue-light">Census</div>
                <div className="badgePageItemInfo hidden">Access the census records for your home, what can you find out about the people who have lived there previously?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 4</div>
                <div className="badgePageItemInfo blue-light">Timeline</div>
                <div className="badgePageItemInfo hidden">Write a timeline of important dates for your local area, inc major changes like building of stations or key roads</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 5</div>
                <div className="badgePageItemInfo blue-light">Historical Figures</div>
                <div className="badgePageItemInfo hidden">Learn about key historical figures in your local area. Make a presentation in the medium of your choice about
                what your area is famous for.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 6</div>
                <div className="badgePageItemInfo blue-light">Treasure Hunt</div>
                <div className="badgePageItemInfo hidden">Create a historical treasure hunt featuring important places in your local area. Share it with someone else.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 7</div>
                <div className="badgePageItemInfo blue-light">Origins</div>
                <div className="badgePageItemInfo hidden">Explore the origins of the names of some local roads or parts of the town</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 8</div>
                <div className="badgePageItemInfo blue-light">Myths and Legends</div>
                <div className="badgePageItemInfo hidden">Research local ghost stories, myths and legends. What historic truths lie beneath them</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 9</div>
                <div className="badgePageItemInfo blue-light">Buildings</div>
                <div className="badgePageItemInfo hidden">If your country has listed buildings, find out about a local grade listed building. Why is it listed? has its purpose been changed?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 10 (Bonus)</div>
                <div className="badgePageItemInfo blue-light">Investigate</div>
                <div className="badgePageItemInfo hidden">Visit and research a local war memorial or graveyard. Choose a name and see what you can find out about the person.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 11 (Bonus)</div>
                <div className="badgePageItemInfo blue-light">Photos</div>
                <div className="badgePageItemInfo hidden">Find historical photos taken in your area. Take new ones in the same places, and compare and contrast.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>    
        </div>
    )
}

export default LocalHistory