import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Dinner1 from '../images/worldtraveller-food1-salmon.jpg';
import Dinner2 from '../images/worldtraveller-food2-salmon.jpg';
import Dinner3 from '../images/worldtraveller-food3-salmon.jpg';
import Dinner4 from '../images/worldtraveller-food4-salmon.jpg';
import Dinner5 from '../images/worldtraveller-food5-salmon.jpg';
import Dinner6 from '../images/worldtraveller-food6-locomoco.jpg';
import Dinner7 from '../images/worldtraveller-food7-locomoco.jpg';
import Dinner8 from '../images/worldtraveller-food8-locomoco.jpg';
import Dinner9 from '../images/worldtraveller-food9-locomoco.jpg';
import Dinner10 from '../images/worldtraveller-food10-custardpie.jpg';
import Dinner11 from '../images/worldtraveller-food11-custardpie.jpg';
import Dinner12 from '../images/worldtraveller-food12-custardpie.jpg';
import Dinner13 from '../images/worldtraveller-food13-custardpie.jpg';
import Dinner14 from '../images/worldtraveller-food14-custardpie.jpg';
import Guide from '../documents/RebelChristchurch.pdf';


const WorldTraveller = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"World Traveller"} />
            <div className="badgePageItem">
                <summary className="badgePageItemTitle blue">01</summary>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Wish list</div>
                    <div className="badgePageItemInfo reverse hidden">Make a list of 10 places abroad which you'd like to travel to in the next five years.</div>
                    <div className="badgePageItemEvidence">
                        <ul className="list">
                            <li>Hawaii</li>
                            <li>The Norwegian Fjords</li>
                            <li>Finland and the Northern Lights</li>
                            <li>Antarctica</li>
                            <li>New Zealand</li>
                            <li>Jamaica - The Luminous Lagoon</li>
                            <li>Ireland</li>
                            <li>Munnar, India - tea picking plantations</li>
                            <li>Japan</li>
                            <li>Fiji - Grand Central Station and Chimneys</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <summary className="badgePageItemTitle blue">03</summary>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Cuisine</div>
                    <div className="badgePageItemInfo reverse hidden">Choose a country and cook at least three traditional dishes from that country.</div>
                    <div className="badgePageItemEvidence">
                        <p>I am combining this with the last clause of my Chef badge and having a Hawaiian dinner party!</p>
                        <br/>
                        <p>I found a brilliant Hawaiian website and selected a starter, a main and a desert.</p>
                        <br/>
                        <ul className="list">
                            <li>Lomi Lomi Salmon: https://onolicioushawaii.com/lomi-lomi-salmon </li>
                            <li>Loco Moco: https://onohawaiianrecipes.com/a-classic-loco-moco</li>
                            <li>Lilikoi Bars: https://onohawaiianrecipes.com/lilikoi-bars</li>
                        </ul>
                        <br/>
                        <p>Check back later to see how it went!!</p>
                        <br/><br/>
                        <p>Update: I had to abandon the Lilikoi Bars as I couldn't get passion fruit juice. I had to work with ingredients I could get locally whilst away on holiday!</p>
                        <ul className="list">
                            <li>Hawaiian Style Custard Pie: https://onohawaiianrecipes.com/hawaiian-style-custard-pie/</li>
                        </ul>
                        <br/><br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Dinner1} alt="Salmon fillets wrapped in cling film" /></div>
                            <div className="galleryItem"><img src={Dinner2} alt="Diced tomato on a plate" /></div>
                            <div className="galleryItem"><img src={Dinner3} alt="Diced tomato and onion on a plate" /></div>
                            <div className="galleryItem"><img src={Dinner4} alt="Cooked salmon fillets, spring onion and a sliced lime on a chopping board" /></div>
                            <div className="galleryItem"><img src={Dinner5} alt="Lomi Lomi Salmon dish served on a saucer" /></div>
                        </div>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Dinner6} alt="Mushrooms and onion cooking in a pan" /></div>
                            <div className="galleryItem"><img src={Dinner7} alt="Hand rolled Beef patties on a baking tray" /></div>
                            <div className="galleryItem"><img src={Dinner8} alt="Beef patties being fried, rice being cooked and gravy bubbling in a pan" /></div>
                            <div className="galleryItem"><img src={Dinner9} alt="Loco Moco served on a dinner plate" /></div>
                        </div>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Dinner10} alt="Ingredients for custard pie being whisked in a bowl" /></div>
                            <div className="galleryItem"><img src={Dinner11} alt="Shortcrust pastry rolled and laid in a square dish ready for filling" /></div>
                            <div className="galleryItem"><img src={Dinner12} alt="Filled pastry dish placed in the oven for baking" /></div>
                            <div className="galleryItem"><img src={Dinner13} alt="Custard Pie in a dish after cooking" /></div>
                            <div className="galleryItem"><img src={Dinner14} alt="Slice of Custard Pie on a side plate" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <summary className="badgePageItemTitle blue">06</summary>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Research rights</div>
                    <div className="badgePageItemInfo reverse hidden">Research three rights which we have in this country which aren't universal around the world.</div>
                    <div className="badgePageItemEvidence">
                        <p> I believe there are many but these three definitely are not universal:</p>
                        <br/>
                        <p><strong>Freedom of thought, conscience and religion</strong></p>
                        <p>"A person is free to hold a broad range of views, beliefs and thoughts and to follow a religious faith."</p>
                        <br/>
                        <p>Some countries do not allow people to express their opinions or beliefs freely, doing so can result in arrest, imprisonment, torture or death.</p>
                        <br/><br/>
                        <p><strong>The Right to Education</strong></p>
                        <p>"A person has the right not to be denied access to the educational system."</p>
                        <br/>
                        <p>Some countries do not have a right to education. The least educated countries include South Sudan, Mali, Central African Republic, Burkina Faso, Benin, Afghanistan, Sierra Leone and Cote d'Ivoire.  Reasons for non-provision of education varies from country to country, but there is no fundamental right to education in these countries.</p>
                        <br/><br/>
                        <p><strong>Prohibition of slavery and forced labour</strong></p>
                        <p>"A person has the absolute right not to be treated as a slave or forced to do compulsory labour. "</p>
                        <br/>
                        <p>The countries where there is no prohibition of slavery and forced labour include North Korea, Eritrea, Burundi, Central African Republic, Afghanistan, Mauritania, South Sudan and Yemen.</p>
                        <br/><br/>
                        Parliament have a nice document that details the human rights in the UK: https://www.parliament.uk/globalassets/documents/education/docs/human-rights.pdf
                        <br/><br/>
                        <br/><br/>
                        <p>The countries with the least personal freedoms are:</p>
                        <ul className="list">
                            <li>Sudan</li>
                            <li>Afghanistan</li>
                            <li>Yemen</li>
                        </ul>
                        <br/>
                        <p>This is according to the Legatum Prosperity Index</p>
                        <br/>
                        <p>Sources:</p>
                        <p>https://www.worldatlas.com/articles/the-countries-most-lacking-in-freedom.html</p>
                        <p>https://www.prosperity.com/rankings</p>
                        <br/><br/>

                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <summary className="badgePageItemTitle blue">07</summary>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Visas</div>
                     <div className="badgePageItemInfo reverse hidden">Research the current visa and health requirements for all the locations on the list you made for Clause 1.</div>
                    <div className="badgePageItemEvidence">
                        <p><strong>Hawaii</strong></p>
                        <br/>
                        <p>Travel to Hawaii requires a visa or visa waiver. The UK is part of the waiver program so if you are visiting for business or pleasure for less than 90 days.</p>
                        <p>Passports need to remain valid for the duration of the stay but don't require additional periods.</p>
                        <p>Covid vaccination is required for entry to the USA, except for children 17 and under.</p>
                        <br/><br/>
                        <p><strong>Norway</strong></p>
                        <br/>
                        <p>You can visit Norway for 90 days without a visa, longer stays require a visa.</p>
                        <p>It may not be possible to use an EHIC or GHIC card in Norway but UK travellers should be able to access healthcare with their passport.</p>
                        <p>Travellers should be up to date with standard UK vaccinations.</p>
                        <p>Tetanus jab is recommended, Rabies and Tick-Borne encephalitis are recommended for some people.</p>
                        <br/><br/>
                        <p><strong>Finland</strong></p>
                        <br/>
                        <p>You can visit Finland for 90 days without a visa, longer stays require a visa.</p>
                        <p>Passports must be issued less than 10 years before entry and be valid for at least 3 months beyond your departure date.</p>
                        <p>Need an EHIC or GHIC card for medical treatment</p>
                        <p>Travellers should be up to date with standard UK vaccinations.</p>
                        <p>Tetanus jab is recommended, Rabies and Tick-Borne encephalitis are recommended for some people.</p>
                        <br/><br/>
                        <p><strong>Antarctica</strong></p>
                        <br/>
                        <p>May require a travel permit - obtained from the Foreign, Commonwealth and Development Office</p>
                        <p>A valid passport is required but there are no other requirements.</p>
                        <p>Travellers should be up to date with standard UK vaccinations.</p>
                        <p>Tetanus jab is recommended, Rabies jab is recommended for some people.</p>
                        <br/><br/>
                        <p><strong>New Zealand</strong></p>
                        <br/>
                        <p>A visa is not required unless you are staying over 6 months, but you will need an Electronic Travel Authority.</p>
                        <p>You must have an onward ticket and enough money to cover your stay.</p>
                        <p>Passport should be valid for three months beyond your departure date.</p>
                        <p>Travellers should be up to date with standard UK vaccinations.</p>
                        <p>Tetanus jab is recommended, Hepatitis B is recommended for some people.</p>
                        <br/><br/>
                        <p><strong>Jamaica</strong></p>
                        <br/>
                        <p>You can visit jamaica for 90 days before a visa is required.</p>
                        <p>Passports must be valid for the duration of your stay.</p>
                        <p>Travellers should be up to date with standard UK vaccinations.</p>
                        <p>A Yellow Fever certificate is required if travelling from or through a risk country</p>
                        <p>Tetanus jab is recommended, Hepatitis B, Hepatitis A and Rabies jabs are recommended for some people.</p>
                        <br/><br/>
                        <p><strong>Ireland</strong></p>
                        <br/>
                        <p>ID is required but a passport is not.</p>
                        <p>Travellers should be up to date with standard UK vaccinations.</p>
                        <p>Tetanus jab is recommended, Rabies is recommended for some people.</p>
                        <br/><br/>
                        <p><strong>India</strong></p>
                        <br/>
                        <p>A visa is required for travel to India and can be applied for online.</p>
                        <p>Travellers should be up to date with standard UK vaccinations.</p>
                        <p>A Yellow Fever certificate is required if travelling from or through a risk country</p>
                        <p>Hepatitis A, Typhoid and Tetanus jabs are recommended. Cholera, Hepatitis B, Japanese encephalitis, Rabies and Tuberculosis are recommended for some people.</p>
                        <br/><br/>
                        <p><strong>Japan</strong></p>
                        <br/>
                        <p>Short-term visitors with a British passport don't need a visa.</p>
                        <p>Passport should be valid for the duration of your stay.</p>
                        <p>Travellers should be up to date with standard UK vaccinations.</p>
                        <p>Tetanus jab is recommended. Hepatitis B, Japanese encephalitis, Rabies and Tick-born encephalitis are recommended for some people.</p>
                        <br/><br/>
                        <p><strong>Fiji</strong></p>
                        <br/>
                        <p>Visa are not needed for visits up to 4 months in length, but you must have an onward / return ticket and a valid visa for your onward destination. </p>
                        <p>Passports should be valid for the duration of the stay.</p>
                        <p>Travellers should be up to date with standard UK vaccinations.</p>
                        <p>A Yellow Fever certificate is required if travelling from or through a risk country</p>
                        <p>Tetanus jab is recommended. Hepatitis A, Hepatitis B, Rabies, Tuberculosis and Typhoid are recommended for some people.</p>
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <summary className="badgePageItemTitle blue">08</summary>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Guide book</div>
                    <div className="badgePageItemInfo reverse hidden">Make a guide for a tourist visiting your town about must-do or see activities, including details of how to travel there.</div>
                    <div className="badgePageItemEvidence">
                        <p>View my guide - <a className="linkUnderline" href={Guide} target="_blank" rel="noopener noreferrer">Christchurch, Where Time is Pleasant</a></p>
                        <br/>
                        <p><strong>Important</strong>: This is a document produced solely for personal use for this specific clause of this badge, it is not intended for use or distribution anywhere. Images may not be correctly licenced and no sources are provided. Please do not save or distribute. All copyright and credit for photographs to their original owners. If there is any problem with anything here please contact me and I will remove the link to the guide.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <summary className="badgePageItemTitle blue">09</summary>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Festivals</div>
                    <div className="badgePageItemInfo reverse hidden">Research and then celebrate a festival which is celebrated in another country.</div>
                    <div className="badgePageItemEvidence">
                        <p>This one I am back dating. I have been interested in Hawaiian culture for a while. I embrace the Hawaiian Ho'Oponopono practice and am currently undertaking additional training.</p>
                        <br/>
                        <p>A while ago, I was fortunate enough to be able to join a ritual through live stream and celebrate this with a Hawaiian community.</p>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

document.onmousedown=disableclick;
function disableclick(e){
    if(e.button === 2) {
    if(window.location.pathname === "/rebel/worldtraveller")
    {
        console.log("right-click blocked");
        alert("Right-click is not permitted on this page");
        return false;
    }
    }
}

export default WorldTraveller