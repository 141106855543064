import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const AdvancedGoodHabits = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Advanced Good Habits"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 1</div>
                <div className="badgePageItemInfo green-light">Observe</div>
                <div className="badgePageItemInfo hidden">Spend at least a week observing your habits. identify which are good and bad.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 2</div>
                <div className="badgePageItemInfo green-light">Keep Up</div>
                <div className="badgePageItemInfo hidden">Have you kept up any of the habits during Good Habits badge? What helped them stay in your routine?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 3</div>
                <div className="badgePageItemInfo green-light">Maintenance</div>
                <div className="badgePageItemInfo hidden">What factors affect whether you maintain a habit or not</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 4</div>
                <div className="badgePageItemInfo green-light">Ideals</div>
                <div className="badgePageItemInfo hidden">Imagine your ideal version of yourself. Choose 4 achievable habits, related to this goal, and incorporate for next 90 days</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 5</div>
                <div className="badgePageItemInfo green-light">Positive Changes</div>
                <div className="badgePageItemInfo hidden">Are there positive changes that you can make to your life which will help to minimise the bad habits?
                eg drinking more water to drink fewer soft drinks, or exercising in the mornings to help you get out of bed earlier. Incorporate 1 into routine.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 6</div>
                <div className="badgePageItemInfo green-light">Embed</div>
                <div className="badgePageItemInfo hidden">Can you find a way to make embedding the good habits into your routine more fun? What works best for you?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default AdvancedGoodHabits