import React from 'react';
import {Link} from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const  ThirdBirthday24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Be Brave"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 1 - Begin either Event Planner or Rebel Community</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>For this clause I started my <Link to="/community">Rebel Community</Link> badge.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Complete a badge from Book One and Book Two</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I've finished Entrepreneur from Book One, there aren't any more from Book Two I can complete at this time, I've already done 28 though so I think this clause is satisfied.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Finish the badge you've had 'in progress' longest</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Celebrate the 3rd Birthday with a party with at least 2 other Rebels.</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Design and make a Rebel-themed party hat</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Help somone in need celebrate their birthday.</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThirdBirthday24