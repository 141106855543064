import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Outdoors1 from '../images/outdoors24-1.jpg';
import Outdoors2 from '../images/outdoors24-2.jpg';

const  Outdoors24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"More Time Outdoors 2024"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Spend more time outdoors</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I don't spend a lot of time outdoors! I set myself a 100 hour target, which I did ultimately exceed.</p>  
                        <br/>
                        <ul className="list">
                            <li>January - 10.58 hours</li>
                            <li>February - 8.38 hours</li>
                            <li>March - 14.70 hours</li>
                            <li>April - 20.50 hours</li>
                            <li>May - 36.25 hours</li>
                            <li>June - 16.33 hours</li>
                            <li>July - 6.87 hours</li>
                            <li>August - 14.37 hours</li>
                            <li>September - 21.77 hours</li>
                            <li>October - 16 hours</li>
                            <li>November - 4.5 hours</li>
                            <li>December - 5.75 hours</li>
                        </ul> 
                        <br/>
                        <p>Total 176 hours in 2024</p>  
                        <br/>
                        <p>Here are my trackers:</p>
                        <img src={Outdoors1} alt="A tree with leaves coloured in, each representing 1 hour outdoors." className="imgThird" />
                        <img src={Outdoors2} alt="A tree with leaves coloured in, each representing 1 hour outdoors." className="imgThird" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Outdoors24