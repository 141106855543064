import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Geographer = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Geographer"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 1</div>
                <div className="badgePageItemInfo brown-light">Types</div>
                <div className="badgePageItemInfo hidden">Understand the difference between physical, human and environmental geography.Choose at least one clause from each type.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 2</div>
                <div className="badgePageItemInfo brown-light">Geographical Changes</div>
                <div className="badgePageItemInfo hidden">Pick a continent and find out how the boundaries, languages spoken and names of countries have changed over time</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 3</div>
                <div className="badgePageItemInfo brown-light">Human Development Index</div>
                <div className="badgePageItemInfo hidden">Research the Human Development Index and decide where is the best place in the world to live</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 4</div>
                <div className="badgePageItemInfo brown-light">Climate Change</div>
                <div className="badgePageItemInfo hidden">Understand the evidence for and against climate change. What are the natural and human causes of climate change?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 5</div>
                <div className="badgePageItemInfo brown-light">UN</div>
                <div className="badgePageItemInfo hidden">What is the UN and how is it working to protect the planet?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 6</div>
                <div className="badgePageItemInfo brown-light">Tourism</div>
                <div className="badgePageItemInfo hidden">Choose a country you do not live in, evaluate the positive and negative impacts of tourism on that country</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 7</div>
                <div className="badgePageItemInfo brown-light">Natural Disasters</div>
                <div className="badgePageItemInfo hidden">Learn about natural disasters and which countries are most affected by the different types. What do they do
                to mitigate the effects?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>    
        </div>
    )
}

export default Geographer