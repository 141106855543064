import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const SoupMar23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Donate soup to a food bank"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Donate soup to a food bank</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">For one-off points for the Rebel Cup</div>
                    <div className="badgePageItemEvidence"> 
                        <p>On 3rd March 2023, I went to Tesco and purchased four tins of soup which I placed in the food bank baskets at the exit on my way out.</p>
                        <br/>
                        <p>I don't have photos of this. It feels a little gauche and virtue signalling to photograph something like this, so it will have to be on trust. I may buy more later in the month and will then photograph just the cans.</p>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SoupMar23