import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Zoologist = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Zoologist"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 1</div>
                <div className="badgePageItemInfo brown-light">Role</div>
                <div className="badgePageItemInfo hidden">What is the role of a zoologist?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 2</div>
                <div className="badgePageItemInfo brown-light">Documentary</div>
                <div className="badgePageItemInfo hidden">Watch a documentary about the work of a zoologist</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 3</div>
                <div className="badgePageItemInfo brown-light">Organisations</div>
                <div className="badgePageItemInfo hidden">Learn about the role of organisations like eaza and biaza. What does a zoo or centre need to do in order
                to become a member?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 4</div>
                <div className="badgePageItemInfo brown-light">Classification</div>
                <div className="badgePageItemInfo hidden">Learn about the 7 classifications of animals. Make classification charts for 10 different species.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 5</div>
                <div className="badgePageItemInfo brown-light">Taxonomies</div>
                <div className="badgePageItemInfo hidden">Know how to classify animals into the 6 main taxonomies. Categorise at least 20 different species that can be found near you.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 6</div>
                <div className="badgePageItemInfo brown-light">Convergent Evolution</div>
                <div className="badgePageItemInfo hidden">Learn about Convergent Evolution</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 7</div>
                <div className="badgePageItemInfo brown-light">IUCN Red List</div>
                <div className="badgePageItemInfo hidden">Leanr about the IUCN Red List and the 9 categories they use. Study one species from each category</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 8</div>
                <div className="badgePageItemInfo brown-light">Citizen Science</div>
                <div className="badgePageItemInfo hidden">Take part in a citizen science project</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default Zoologist