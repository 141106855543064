import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Librarian = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Librarian"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 1</div>
                <div className="badgePageItemInfo olive-light">Cataloguing</div>
                <div className="badgePageItemInfo hidden">Learn about the different ways to catalogue books, including the dewey decimal system and genrefication</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 2</div>
                <div className="badgePageItemInfo olive-light">Personal Library</div>
                <div className="badgePageItemInfo hidden">Reorganise and Catalogue your own book collection as a personal library</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 3</div>
                <div className="badgePageItemInfo olive-light">Bookplate</div>
                <div className="badgePageItemInfo hidden">Design a bookplate for your home library</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 4</div>
                <div className="badgePageItemInfo olive-light">Local Library</div>
                <div className="badgePageItemInfo hidden">Get to know your local library. What additional services does it provide? Dies it run events?
                How can you get involved or support it?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 5</div>
                <div className="badgePageItemInfo olive-light">Read</div>
                <div className="badgePageItemInfo hidden">Read a book in a manner you've never tried before - audiobook, kindle, interlinear </div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 6</div>
                <div className="badgePageItemInfo olive-light">Banned</div>
                <div className="badgePageItemInfo hidden">Learn about 10 banned books - why were they banned and where?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 7</div>
                <div className="badgePageItemInfo olive-light">Record</div>
                <div className="badgePageItemInfo hidden">Find a creative way to record all the books you read in a year</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 8</div>
                <div className="badgePageItemInfo olive-light">Make</div>
                <div className="badgePageItemInfo hidden">Make a book nook, book ends, a fancy bookmark, or a fake book storage box</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default Librarian