import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Meal1 from '../images/linguist-meal1.jpg';
import Meal2 from '../images/linguist-meal2.jpg';
import Meal3 from '../images/linguist-meal3.jpg';
import TV1 from '../images/linguist-fiasco.jpg';
import TV2 from '../images/linguist-elite.jpg';
import TV3 from '../images/linguist-youngroyals.jpg';

const Linguist = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Linguist"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Practice for 3 months</div>
                    <div className="badgePageItemInfo reverse hidden">Choose a foreign language to improve over the next 3 months</div>
                    <div className="badgePageItemEvidence">
                        <p>I have actually earned this badge three times over by learning French, Spanish and Swedish simultaneously!</p>
                        <p>Each language is at a different level.</p>
                        <ul className='list'>
                            <li>French - my strongest language. I learned French at school and have a GCSE A grade in that, I did at one time want to work as an Intepreter or Translator so I did extra study in French at that time.</li>
                            <li>Spanish - I started teaching myself Spanish a few years ago but hadn't continued, so I was starting from a basic level of knowledge. Nowhere near at the level of French, but also not a complete beginner.</li>
                            <li>Swedish - complete beginner level! I wanted something different to learn and I have to say I'm loving it! Swedish is the one I look forward to practicing the most!</li>
                            <li>Hawaiian - complete beginner level, added later in the year.</li>
                        </ul>                        
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Dedicate time</div>
                    <div className="badgePageItemInfo reverse hidden">Dedicate at least 2 hours a week to practicing your chosen languages</div>
                    <div className="badgePageItemEvidence">
                        <p>I actually do 1-2 hours per day of practice and do lessons in each language every day.</p>
                        <p>I have covered over 2000 words of French and over 1000 words in Spanish and Swedish so far in 72 days.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">App</div>
                    <div className="badgePageItemInfo reverse hidden">Download an app to help with your learning</div>
                    <div className="badgePageItemEvidence">
                        <p>I have downloaded and am regularly using the following apps for all three languages:</p>
                        <ul className='list'>
                            <li>DuoLingo</li>
                            <li>LingQ</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Conversation</div>
                    <div className="badgePageItemInfo reverse hidden">Have a conversation (of the relevant level for your ability) with a native speaker</div>
                    <div className="badgePageItemEvidence">
                        <p>I do this using AI language bots.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Translate</div>
                    <div className="badgePageItemInfo reverse hidden">Choose a book, magazine or article (of the relevant language) which you will read by the end of the three months. Translate a section of it into English.</div>
                    <div className="badgePageItemEvidence">
                        <p>I am constantly translating articles, posts and songs. I have a Swedish beginners book that I am working through.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Cook</div>
                    <div className="badgePageItemInfo reverse hidden">Cook a dish from a country where your chosen language is spoken.</div>
                    <div className="badgePageItemEvidence">
                        <p>France - Chicken Chasseur. https://www.bbcgoodfood.com/recipes/one-pot-chicken-chasseur</p>
                        <p>Spain - Smoky Albondigas. https://www.olivemagazine.com/recipes/meat-and-poultry/smoky-albondigas/</p>
                        <p>Sweden - Tomato Ketchup Pasta. https://www.yummytummyaarthi.com/tomato-ketchup-pasta-recipe</p>
                    </div>
                    <br/>
                    <p>Excuse the small portions, I have a stupidly tiny appetite! I added extra ketchup to my Swedish disk for extra authenticity!</p>
                    <div className="gallery">
                        <div className="galleryItem"><img src={Meal1} alt={"Chicken chasseur cooked and on a plate ready to eat."}/></div>
                        <div className="galleryItem"><img src={Meal2} alt={"Smoky Albondigas cooked and on a plate ready to eat."}/></div>
                        <div className="galleryItem"><img src={Meal3} alt={"Tomato Ketchup Pasta cooked and on a plate ready to eat."}/></div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Watch</div>
                    <div className="badgePageItemInfo reverse hidden">Watch a film or TV show in your chosen language (you can keep the English subtitles on).</div>
                    <div className="badgePageItemEvidence">
                        <p>French - Fiasco. A limited series, comedy about a hapless first-time director whose every disaster is captured by a behind the scenes camera crew. I found this very entertaining!</p>
                        <p>Spanish - Elite. A story of how three working-class teens enrolling into an exclusive private school in Spain leads to murder. Literally. There are 7 seasons so it might take me a while to finish!</p>
                        <p>Swedish - Young Royals. I love this show, I've watched multiple times with English dub, with English dub and Swedish subtitles and in Swedish with English subtitles!</p>
                    </div>
                    <div className="gallery">
                        <div className="galleryItem"><img src={TV1} alt={"Artwork for the TV series Fiasco."}/></div>
                        <div className="galleryItem"><img src={TV2} alt={"Artwork for the TV Series Elite."}/></div>
                        <div className="galleryItem"><img src={TV3} alt={"Artwork for the TV Series Young Royals."}/></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Linguist