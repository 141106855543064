import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Cook1 from '../images/nov24-readyrebelcook-1.jpg';
import Cook2 from '../images/nov24-readyrebelcook-2.jpg';
import Cook3 from '../images/nov24-readyrebelcook-3.jpg';
import Cook4 from '../images/nov24-readyrebelcook-4.jpg';
import Cook5 from '../images/nov24-readyrebelcook-5.jpg';


const November24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Ready Rebel Cook"} />
            <p><strong>This page provides my evidence for the Ready Rebel Cook monthly badge.  The information for this badge is available publicly on the Rebel Badge Store Noticeboard so descriptions haven't been removed.</strong></p>
            <br/>
            <p>The information on this page is a summary of what I did to meet the clauses - it isn't necessarily representative of all my research and work.</p>
            
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Cook a meal from surprise items either given to you or chosen by someone else.</div>
                <div className="badgePageItemContent">
                   <p>I loved this clause! For my birthday this year I threw a party for the family, and I asked them to each bring with them one savoury ingredient to contribute to our meal.</p>
                   <p>It confused everyone a little, no one was sure what to bring! However, this is what they brought me:</p>
                   <img src={Cook1} alt="Ingredients displayed on a kitchen surface ready for cooking" className="imgThird"/>
                   <br/><br/>
                   <p>I then used the SuperCook app to list the ingredients I had (along with one or two cupboard staples that I already had) and searched for recipes I could make reasonably quickly! These are the recipes I made:</p>
                   <ul className="list">
                    <li>Roast Potatoes and Crispy Bacon</li>
                    <li>Sausage meat sauce for pasta (sausage meat, peppers, tomatoes, garlic, onion, basil and celery)</li>
                    <li>Egg with baked beans (eggs, tomatoes, baked beans, chorizo)</li>
                   </ul>
                   <br/>
                   <p>Unfortunately in all the mayhem, I forgot to take photos of my final dishes. I can say that there were absolutely no leftovers though, they went down very well!</p>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle grey">Make a meal only using items currently in your cupboard.</div>
                <div className="badgePageItemContent">
                   <p>For this, I cooked one of my favourite quick dishes, Garlic Mushroom pasta</p>
                   <img className="imgThird" src={Cook2} alt="Spaghetti with a mushroom sauce on a plate"/>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Make a Showstopper Dessert</div>
                <div className="badgePageItemContent">
                   <p>I had great fun doing this with my daughter and niece for my birthday. We made a Gluten Free White Chocolate and Ginger Cheesecake.</p>
                   <img className="imgThird" src={Cook3} alt="White Chocolate and Ginger Cheesecake with gingerbread men dipped in chocolate as decorations on the top" />
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Make a meal inspired by a film or tv show.</div>
                <div className="badgePageItemContent">
                   <p>For this I chose to make Ketchup Pasta, which was inspired by Young Royals. I had never heard of doing this before I watched the show, but it's quick and easy!</p>
                   <img src={Cook4} alt="Pasta with tomato ketchup on a plate" className="imgThird" />
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle grey">Cook a meal including the oldest item in your freezer.</div>
                <div className="badgePageItemContent">
                   <p>I don't have anything very old in my freezer, as I only buy what I need each month, but I did have a batch of Smoky Albondigas frozen from when I did my linguist badge, so I used those to fulfil this clause.</p>
                    <img src={Cook5} alt="A portion of Smoky Albondigas on a plate" className="imgThird"/>
                </div>
            </div>
        </div>
    )
}

export default November24