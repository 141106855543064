import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Classicist = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Classicist"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 1</div>
                <div className="badgePageItemInfo blue-light">Language</div>
                <div className="badgePageItemInfo hidden">Choose a historical language and study it for a period of at least three months</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 2</div>
                <div className="badgePageItemInfo blue-light">Vocabulary</div>
                <div className="badgePageItemInfo hidden">Spend at least two hours a week improving your vocabulary and translation skills</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 3</div>
                <div className="badgePageItemInfo blue-light">Read</div>
                <div className="badgePageItemInfo hidden">Read at least three non-fiction books about your chosen period of history - eg if you have chosen to study Latin
                learn about the Romans</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 4</div>
                <div className="badgePageItemInfo blue-light">Stories</div>
                <div className="badgePageItemInfo hidden">Read at least three stories originally written in your chosen language - eg Greek mythology</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 5</div>
                <div className="badgePageItemInfo blue-light">Impact</div>
                <div className="badgePageItemInfo hidden">In what ways has your chosen language and ancient civilisation affected the way we live today? Find an interesting way to present your findings</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 6</div>
                <div className="badgePageItemInfo blue-light">Equivalents</div>
                <div className="badgePageItemInfo hidden">Choose an aspect of your chosen civilisation which no longer exists today. Is there a modern equivalent? If not, why not?
                What can we learn from it?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default Classicist