import React from 'react';
import {Link} from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Book1 from '../images/annual-52books24-1.jpg';
import Book2 from '../images/annual-52books24-2.jpg';
import Book3 from '../images/annual-52books24-3.jpg';
import Book4 from '../images/annual-52books24-4.jpg';

const  Books24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"52 Books in a Year"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle darkblue">Read 52 Books in a Year</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I am going to partly back-date this one. I read 44 books last year for the Pop Sugar 2023 reading challenge to complete my <Link to="/advancedreader" className="linkUnderline">Advanced Reader</Link> badge, and another 3 that I know of on top of that.</p>
                        <p>I need to read another 8 books by the end of February to complete this challenge.</p>       
                        <br/>
                        <p>Here are the additional books I have read:</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Book1} alt="Book titled One August Night" /></div>
                            <div className="galleryItem"><img src={Book2} alt="Book titled The Empire" /></div>
                            <div className="galleryItem"><img src={Book3} alt="Book titled Dark Tides" /></div>
                            <div className="galleryItem"><img src={Book4} alt="Book titled Seven and a Half Lessons About the Brain" /></div>
                        </div>  
                        <br/>
                        <p>I seem to be missing photos of the others, so you're just going to have to trust that I have completed this!</p>            
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Books24