import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Beautician = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Beautician"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 1</div>
                <div className="badgePageItemInfo green-light">Skin</div>
                <div className="badgePageItemInfo hidden">Understand the anatomy and physiology of skin</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 2</div>
                <div className="badgePageItemInfo green-light">Products</div>
                <div className="badgePageItemInfo hidden">What do cleanser, toner and moisturiser do</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 3</div>
                <div className="badgePageItemInfo green-light">Hair</div>
                <div className="badgePageItemInfo hidden">Understand the anatomy and physiology of hair</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 4</div>
                <div className="badgePageItemInfo green-light">Hair Types</div>
                <div className="badgePageItemInfo hidden">What type of hair do you have? What hair products work best for it? Are there any natural ingredients which
                might be of benefit to your hair type?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 5</div>
                <div className="badgePageItemInfo green-light">Makeup</div>
                <div className="badgePageItemInfo hidden">Learn how different makeup products are used, what different products are designed specifically for older skin?
                Which brands cater for different skin tones.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 6</div>
                <div className="badgePageItemInfo green-light">Face and Hair</div>
                <div className="badgePageItemInfo hidden">Learn about the impact of face shape on haircuts. Which styles suit your face?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 7</div>
                <div className="badgePageItemInfo green-light">Colours</div>
                <div className="badgePageItemInfo hidden">Experiment with colour palettes. Which colours suit your hair, eye and skin colour best? Are there any which do not work at all?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 8</div>
                <div className="badgePageItemInfo green-light">Project</div>
                <div className="badgePageItemInfo hidden">3 months experimenting with your own makeup, different brands and methods OR 3mths learn about stage makeup and create a look for a character or cosplay.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default Beautician