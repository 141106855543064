import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const GraphicDesigner = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Graphic Designer"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 1</div>
                <div className="badgePageItemInfo purple-light">Learn</div>
                <div className="badgePageItemInfo hidden">Learn about the different types of graphic design software available. Compare their cost, accessibiilty, and the level of prior knowledge required to use them. Choose the best program for you, and take a short course in it.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 2</div>
                <div className="badgePageItemInfo purple-light">Pinterest</div>
                <div className="badgePageItemInfo hidden">Put together a Pinterest board or physical mood board about a certain design style, e.g. grunge, minimalism, pop art, Bauhaus. Use it to find inspiration for your own work.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 3</div>
                <div className="badgePageItemInfo purple-light">Glossary</div>
                <div className="badgePageItemInfo hidden">Create a glossary of important graphic design phrases and abbreviations. Where items are alternatives - eg cmyk or rgb - make sure you understand the differences between the two. Suggested file formats to include are png, jpg, svg, gif and an undertanding of hex codes and pantones.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 4</div>
                <div className="badgePageItemInfo purple-light">Poster</div>
                <div className="badgePageItemInfo hidden">Make a poster or similar using two different graphic design apps or programs. Compare and contrast how easy each method was to use.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 5</div>
                <div className="badgePageItemInfo purple-light">Design</div>
                <div className="badgePageItemInfo hidden">Make, print and frame a piece of art you have made using graphic design software.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 6</div>
                <div className="badgePageItemInfo purple-light">Projects</div>
                <div className="badgePageItemInfo hidden">Use your new design skills to create at least three different projects not related to your job - these might include making 
                a logo and posters for an event, designing leaflets or invitations, redesigning a website, creating a booklet, creating a social media style guide and templagtes etc.
                </div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default GraphicDesigner