import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Upcycler = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Upcycler"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 1</div>
                <div className="badgePageItemInfo purple-light">Learn</div>
                <div className="badgePageItemInfo hidden">Learn about different methods of upcycling. Research necessary equipment and safety precautions before beginning
                any projects. You may wish to complete carpenter and designer first.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Project 1</div>
                 <div className="badgePageItemInfo purple-light">Refresh</div>
                <div className="badgePageItemInfo hidden">Update an existing piece of furniture with wallpaper or new components</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Project 2</div>
                 <div className="badgePageItemInfo purple-light">Second Hand</div>
                <div className="badgePageItemInfo hidden">use second-hand clothing to make a non-clothing household item</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Project 3</div>
                 <div className="badgePageItemInfo purple-light">Rubbish</div>
                <div className="badgePageItemInfo hidden">Make something useful out of items which would normally be considered rubbish</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
        </div>
    )
}

export default Upcycler