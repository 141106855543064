import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Barista = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"barista"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 1</div>
                <div className="badgePageItemInfo olive-light">Research</div>
                <div className="badgePageItemInfo hidden">Research the history of your chosen beverage, inc how it found its way to your country. Present in an interesting way.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 2</div>
                <div className="badgePageItemInfo olive-light">Diagram</div>
                <div className="badgePageItemInfo hidden">Draw a diagram to show the journey from growing a coffee bean / tea leaf / cocoa bean, all the way to your mug</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 3</div>
                <div className="badgePageItemInfo olive-light">Types</div>
                <div className="badgePageItemInfo hidden">Choose at least 5 different types of your chosen drink, learn about their origins and differences in appearance,
                scent and flavour profile</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 4</div>
                <div className="badgePageItemInfo olive-light">Make</div>
                <div className="badgePageItemInfo hidden">Explore the different ways to make your chosen drink - eg temp, amount of milk, use of syrups, fresh ingredients,
                additional garnish, use of different brewing equipment. Get confident making a wide variety of your chosen beverage</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 5</div>
                <div className="badgePageItemInfo olive-light">Sellers</div>
                <div className="badgePageItemInfo hidden">Make a list of at least six different shops or cafes which serve your chosen beverage. Visit each one and rate your drink
                for taste, temp, presentation, value for money, ambience and overall experience.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 6</div>
                <div className="badgePageItemInfo olive-light">Ethics</div>
                <div className="badgePageItemInfo hidden">Learn about ethical production of your chosen drink and the various ethical marks used on packaging in your country.
                Understand the concerns related to Fair Trade supply.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 7</div>
                <div className="badgePageItemInfo olive-light">Event</div>
                <div className="badgePageItemInfo hidden">Host some form of event related to your chosen drink, eg japanese tea ceremony, afternoon tea. If possible, with
                your chosen drink, create basic foam art as part of your offerings.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default Barista