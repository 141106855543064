import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const MarineBiologist = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Marine Biologist"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 1</div>
                <div className="badgePageItemInfo brown-light">Cetaceans</div>
                <div className="badgePageItemInfo hidden">Learn how to identify different types of cetaceans and which sub-orders they belong to</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 2</div>
                <div className="badgePageItemInfo brown-light">Conservation</div>
                <div className="badgePageItemInfo hidden">Take part in a marine wildlife survey, conservation project or a marine-related citizen science project.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 3</div>
                <div className="badgePageItemInfo brown-light">Wildlife Code</div>
                <div className="badgePageItemInfo hidden">Research the marine and coastal wildlife code. Leanr who to call for local aquatic life in distress</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 4</div>
                <div className="badgePageItemInfo brown-light">Species</div>
                <div className="badgePageItemInfo hidden">How do aquatic species differ between salt water, fresh water and brackish water?How does the salinity affect their biology?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 5</div>
                <div className="badgePageItemInfo brown-light">Current Issues</div>
                <div className="badgePageItemInfo hidden">Research a current marine conservation issue. Are there steps which you can take to help tackle it?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 6</div>
                <div className="badgePageItemInfo brown-light">Invasive Species</div>
                <div className="badgePageItemInfo hidden">Learn about invasive aquatic species and how they affect native species. How is your government tackling them?
                Where possible, join a conservation crew working to remove them.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 7</div>
                <div className="badgePageItemInfo brown-light">Deep Ocean</div>
                <div className="badgePageItemInfo hidden">Learn about a newly discovered deep ocean species. What adaptationss have deep sea creatures developed to
                survive extreme conditions.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 8</div>
                <div className="badgePageItemInfo brown-light">Sustainable Fishing</div>
                <div className="badgePageItemInfo hidden">Learn about sustainable fishing practices. If you eat fish, use your purchasing power to support sustainable fishing.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 9</div>
                <div className="badgePageItemInfo brown-light">Tagging</div>
                <div className="badgePageItemInfo hidden">Learn how marine biologists tag creatures for future identification</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>     
        </div>
    )
}

export default MarineBiologist