import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const WildCrafter = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Wild Crafter"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 1</div>
                <div className="badgePageItemInfo brown-light">Silver Clay</div>
                <div className="badgePageItemInfo hidden">Mae an item of jewellery using a natural find and silver clay</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 2</div>
                <div className="badgePageItemInfo brown-light">Natural Dyes</div>
                <div className="badgePageItemInfo hidden">Experiment with natural dyes. Dye a piece of cloth and make something useful with it.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 3</div>
                <div className="badgePageItemInfo brown-light">Wind Chime</div>
                <div className="badgePageItemInfo hidden">make a driftwood wind chime</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 4</div>
                <div className="badgePageItemInfo brown-light">Seed Paper</div>
                <div className="badgePageItemInfo hidden">Make paper with dried flowers or seeds in it</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 5</div>
                <div className="badgePageItemInfo brown-light">Stone Painting</div>
                <div className="badgePageItemInfo hidden">Try mandala stone painting</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default WildCrafter