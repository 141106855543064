import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Xmas1 from '../images/dec24-bigkidwinter-1.jpg';
import Xmas2 from '../images/dec24-bigkidwinter-2.jpg';
import Xmas3 from '../images/dec24-bigkidwinter-3.jpg';
import Xmas4 from '../images/dec24-bigkidwinter-4.jpg';
import Xmas5 from '../images/dec24-bigkidwinter-5.jpg';
import Xmas6 from '../images/dec24-bigkidwinter-6.jpg';
import Xmas7 from '../images/dec24-bigkidwinter-7.jpg';
import Xmas8 from '../images/dec24-bigkidwinter-8.jpg';
import Xmas9 from '../images/dec24-bigkidwinter-9.jpg';


const December24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Big Kid Winter"} />
            <p><strong>This page provides my evidence for the Big Kid Winter monthly badge.  The information for this badge is available publicly on the Rebel Badge Store Noticeboard so descriptions haven't been removed.</strong></p>
            <br/>
            <p>The information on this page is a summary of what I did to meet the clauses - it isn't necessarily representative of all my research and work.</p>
            
            <div className="badgePageItem">
                <div className="badgePageItemTitle lilac">Decorate a tree</div>
                <div className="badgePageItemContent">
                   <p>I have a real tree every year that I decorate. Unfortunately I don't seem to have taken a photo of the decorated tree this year, which is unusual! Here it is before decoration and with just the angel and lights on it.</p>
                   <img src={Xmas1} alt="Real Christmas tree in a bay window ready for decorating" className="imgThird" />
                   <img src={Xmas2} alt="Real Christmas tree in a bay window decorated with an angel on the top and lit up colourful lights" className="imgThird" />
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle lilac">Make a handmade card</div>
                <div className="badgePageItemContent">
                   <p>I made this Pergamano card</p>
                   <img src={Xmas3} alt="A Christmas wreath made using the Pergamano technique" className="imgThird" />
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle grey">Watch a Christmas movie</div>
                <div className="badgePageItemContent">
                   <p>I watched a few, but for the purposes of this it has to be Elf! which I always watch whilst putting up the decorations!</p>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle grey">Visit a light show or take time to appreciate local festive lights</div>
                <div className="badgePageItemContent">
                    <p>Dad and I took a drive around local villages to see the different light displays. The photos are bad, but it shows we were there!</p>
                    <img src={Xmas4} alt="Christmas lights in a high street at night taken through a car windscreen" className="imgThird"/>
                    <img src={Xmas5} alt="Christmas lights in a high street at night taken through a car windscreen" className="imgThird"/>
                    <img src={Xmas6} alt="Christmas lights in a high street at night taken through a car windscreen" className="imgThird"/>
                    <img src={Xmas7} alt="Christmas lights in a high street at night taken through a car windscreen" className="imgThird"/>
                    <img src={Xmas8} alt="Christmas lights in a high street at night taken through a car windscreen" className="imgThird"/>
                    <img src={Xmas9} alt="Christmas lights in a high street at night taken through a car windscreen" className="imgThird"/>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle lilac">Play secret santa</div>
                <div className="badgePageItemContent">
                   <p>We have a family secret santa every year. To save money for everyone, each adult buys just one gift for one other person ensuring that everyone gets a christmas gift but doesn't have to buy for everyone.</p>
                </div>
            </div>
        </div>
    )
}

export default December24