import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const RebelCommunity = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"RebelCommunity"} area={"bookthree"} />
            <p className="singerIntro">I started this badge back in 2024 when the syllabus was first announced in the group.</p>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 1</div>
                 <div className="badgePageItemInfo blue-light">Facebook Group</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    <p>Although I haven't been as active in the last coupke of months, I have previously been active in helping and encouraging in the group and posting my work.</p>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 2</div>
                 <div className="badgePageItemInfo blue-light">Local Rebellion</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    <p>I am an admin in my local rebellion. I don't post in there as frequently now as I understand it shoul dbe used for meetups, not accountability. I have tried organising physical and virtual meetups, but it seems that no one is interested.</p>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 3</div>
                 <div className="badgePageItemInfo blue-light">Meet Ups</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    <p>I attended December's Rebel Fest</p>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 4</div>
                 <div className="badgePageItemInfo blue-light">Rebel Cup</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    <p>I earned 151 cup points this year and have been in the patrol facebook group.</p>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 5</div>
                 <div className="badgePageItemInfo blue-light">Other Rebel Groups</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    <p>I am a member of Pen Pals and Readers</p>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 3</div>
                 <div className="badgePageItemInfo blue-light">Recruit</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    <p>I recruited a friend to help me with the buddy aspect of my Silver Maverick.</p>
                </div>
            </div>
           
            
        </div>
    )
}

export default RebelCommunity