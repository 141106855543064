import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const MyPersonality = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"My Personality"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Clause 1</div>
                <div className="badgePageItemInfo hidden">Complete 5 different personality tests. Analyse results. How accurate do you think they are?
                What can you learn from the results? Are there any areas you'd like to work on?
                Do they suggest the types of people you work best with / are friends with / should be in a relationship with? Does this line up with your life?
                Learn about the origins of at least three of the tests you took. How widely used are the tests? What do critics say about them? Are they used by employers? If so, how and why?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default MyPersonality