import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const MyOrigins = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"My Origins"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Clause 1</div>
                <div className="badgePageItemInfo pink-light">Research</div>
                <div className="badgePageItemInfo hidden">Spend at least 3 months researching and creating a project of your own choice to explore and celebrate your
                cultural heritage.  Family archives, talk to family members, research are of past. Leanr more about an aspect of your heritage - language, food, religion etc.
                Share in a way to inspire others.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default MyOrigins