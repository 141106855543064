import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const MoreExercise25 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Portrait of Nelson Mandela"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Do more exercise during 2025</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">For one-off points for the Rebel Cup</div>
                    <div className="badgePageItemEvidence"> 
                        Coming soon!
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoreExercise25