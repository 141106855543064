import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const AdvancedBrainGames = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Advanced Brain Games"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 1</div>
                <div className="badgePageItemInfo green-light">Puzzles</div>
                <div className="badgePageItemInfo hidden">Complete an entire book of your favourite type of puzzle</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 2</div>
                <div className="badgePageItemInfo green-light">Progress</div>
                <div className="badgePageItemInfo hidden">Choose a specific type of brain game, Do 3 times a week for 3 months. Track Progress.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 3</div>
                <div className="badgePageItemInfo green-light">Mystery</div>
                <div className="badgePageItemInfo hidden">Complete and solve a mystery puzzle</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 4</div>
                <div className="badgePageItemInfo green-light">Spot the Difference</div>
                <div className="badgePageItemInfo hidden">Try some spot the difference puzzles created for adults</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 5</div>
                <div className="badgePageItemInfo green-light">Hidden Objects</div>
                <div className="badgePageItemInfo hidden">Try some hidden object illustration puzzles, like Where's Wally</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 6</div>
                <div className="badgePageItemInfo green-light">Brain teaser</div>
                <div className="badgePageItemInfo hidden">Solve a physical brain teaser made out of either wood or metal</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 7</div>
                <div className="badgePageItemInfo green-light">Cryptic</div>
                <div className="badgePageItemInfo hidden">Learn about cryptic crossword puzzles and how they are set. Spend at least a month getting better at solving them.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
        </div>
    )
}

export default AdvancedBrainGames