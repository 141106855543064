import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const CakeArtist = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Cake Artist"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 1</div>
                <div className="badgePageItemInfo purple-light">Fondant</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 2</div>
                <div className="badgePageItemInfo purple-light">Buttercream Frosting</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 3</div>
                <div className="badgePageItemInfo purple-light">Royal Icing</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 4</div>
                <div className="badgePageItemInfo purple-light">Mirror Glaze</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 5</div>
                <div className="badgePageItemInfo purple-light">Piping</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 6</div>
                <div className="badgePageItemInfo purple-light">Stencils</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 7</div>
                <div className="badgePageItemInfo purple-light">Sugarwork & Edible Decoration</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 8</div>
                <div className="badgePageItemInfo purple-light">Inedible Decoration</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
        </div>
    )
}

export default CakeArtist