import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';


const  SpringClean24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Big Rebel Spring Clean 2024"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Litter Pick</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>For this badge I organised a litter pick at Christchurch Quay with my local rebellion. However, of 4 people who signed up, none of them turned up at the event with only a couple letting me know.</p>
                        <p>Regardless, I litter pick solo every weekend anyway, so can more than fulfill this clause.</p>      
                        <p>In 2024, I have collected over 9000 pieces of litter.</p>      
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpringClean24