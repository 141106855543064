import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Brewer = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Brewer"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 1</div>
                <div className="badgePageItemInfo olive-light">Learn</div>
                <div className="badgePageItemInfo hidden">Learn about the history of Inns, Ale Houses and Taverns. Explore the origins of the oldest pub names in your local area.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 2</div>
                <div className="badgePageItemInfo olive-light">Beer and Cider</div>
                <div className="badgePageItemInfo hidden">Learn about different types of either beer or cider. What makes them different?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 3</div>
                <div className="badgePageItemInfo olive-light">Cooking</div>
                <div className="badgePageItemInfo hidden">Learn how to cook with beer or cider. How is it best used in a recipe? Cook a meal which incorporates it.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 4</div>
                <div className="badgePageItemInfo olive-light">Glasses</div>
                <div className="badgePageItemInfo hidden">Learn about at least 10 different types of beer glass. Which drinks are they used for and why?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 5</div>
                <div className="badgePageItemInfo olive-light">Women</div>
                <div className="badgePageItemInfo hidden">Learn about the historic role of women in brewing, and when and why that changed</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 6</div>
                <div className="badgePageItemInfo olive-light">Pairing</div>
                <div className="badgePageItemInfo hidden">Learn about beer and food pairing and create a menu with complementary beers for each course.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default Brewer