import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Medal from '../images/fundraiser-2.jpg';

const  RebelCause24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel with a Cause 2024"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Make a positive impact for a charity of cause of your choice.</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>This year I raised £125 for the RNLI by taking part in their Mayday Mile.</p> 
                        <img src={Medal} alt="RNLI Mayday Mile medal" className="imgThird" />
                                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RebelCause24