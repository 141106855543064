import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const AdvancedAnimalLover = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Advanced Animal Lover"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 1</div>
                <div className="badgePageItemInfo blue-light">Project</div>
                <div className="badgePageItemInfo hidden">Play an active part in a project to help an animal which is endangered, either in your region or abroad</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 2</div>
                <div className="badgePageItemInfo blue-light">Rewilding</div>
                <div className="badgePageItemInfo hidden">Investigate rewilding and reintroduction projects around the world. Consider pros and cons of returning
                apex predators to the wild, and examples of man-made problems caused by introducing non-native species to an area.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 3</div>
                <div className="badgePageItemInfo blue-light">Sponsor</div>
                <div className="badgePageItemInfo hidden">Sponsor an animal - either in the wild or at a sanctuary - or donate animal care products to a 
                foodbank or sanctuary. Have a clear understanding of how your donation will be used.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 4</div>
                <div className="badgePageItemInfo blue-light">Diet</div>
                <div className="badgePageItemInfo hidden">Review your diet and purchasing habits. What changes can you make to help animals? Try one for at least three months</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 5</div>
                <div className="badgePageItemInfo blue-light">Organisations</div>
                <div className="badgePageItemInfo hidden">Learn about an organisation which works specifically with animals - either in your area or abroad. Fundraise for the organisation.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default AdvancedAnimalLover