import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Archaeologist = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Archaeologist"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 1</div>
                <div className="badgePageItemInfo brown-light">Watch</div>
                <div className="badgePageItemInfo hidden">Watch a documentary or read a book about a significant archaeological discovery</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 2</div>
                <div className="badgePageItemInfo brown-light">Timeline</div>
                <div className="badgePageItemInfo hidden">Make a tieline of major archaeological discoveries. Map them around the world</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 3</div>
                <div className="badgePageItemInfo brown-light">Local</div>
                <div className="badgePageItemInfo hidden">Find out about digs in your local area and what has been discovered near where you live. Where possible attend an open day </div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 4</div>
                <div className="badgePageItemInfo brown-light">Burials</div>
                <div className="badgePageItemInfo hidden">Look at burial rituals and how they differ geographically and across time.
                Research how archaeologists can recognise that a grave has been previously disturbed by grave robbers.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 5</div>
                <div className="badgePageItemInfo brown-light">Recording</div>
                <div className="badgePageItemInfo hidden">Learn about site recording and the symbols used on drawings of sites. Make a site plan of a well knwon archaeological site.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 6</div>
                <div className="badgePageItemInfo brown-light">Dating</div>
                <div className="badgePageItemInfo hidden">Explain the diffference between relative and absolute dating, and give examples of each type of dating  using
                artefacts, structures and sites.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 7</div>
                <div className="badgePageItemInfo brown-light">Anomalies</div>
                <div className="badgePageItemInfo hidden">learn about at least five archaeological anomalies around the world and the theories which relate to them.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 8</div>
                <div className="badgePageItemInfo brown-light">Photography</div>
                <div className="badgePageItemInfo hidden">Learn about the various uses of photography in archaeology - including artefact documentation, excavation records
                fluorescence photography, endoscopic photography and thermal infrared imagery.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default Archaeologist