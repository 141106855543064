import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const AdvancedBaker = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Advanced Baker"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 1</div>
                <div className="badgePageItemInfo olive-light">Sourdough</div>
                <div className="badgePageItemInfo hidden">Cultivate your own sourdough and make a loaf</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 2</div>
                <div className="badgePageItemInfo olive-light">Cake</div>
                <div className="badgePageItemInfo hidden">Make a showstopper cake including at least 2 tiers</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 3</div>
                <div className="badgePageItemInfo olive-light">Biscuit</div>
                <div className="badgePageItemInfo hidden">Make a biscuit sculpture</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 4</div>
                <div className="badgePageItemInfo olive-light">Traditional</div>
                <div className="badgePageItemInfo hidden">Make 3 different traditional bakes from a foreign country of your choice</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 5</div>
                <div className="badgePageItemInfo olive-light">Dietary</div>
                <div className="badgePageItemInfo hidden">Adapt a bake to accommodate a dietary requirement</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 6</div>
                <div className="badgePageItemInfo olive-light">Patisserie</div>
                <div className="badgePageItemInfo hidden">Make 3 types of French patisserie which are new to you</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 7</div>
                <div className="badgePageItemInfo olive-light">Heritage</div>
                <div className="badgePageItemInfo hidden">Research and make a heritage bake from your region</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 8</div>
                <div className="badgePageItemInfo olive-light">Pastry</div>
                <div className="badgePageItemInfo hidden">Make all butter puff pastry from scratch</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 9</div>
                <div className="badgePageItemInfo olive-light">Bread</div>
                <div className="badgePageItemInfo hidden">Make a plaited bread</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default AdvancedBaker