import React from 'react'
import { Link } from 'react-router-dom';
import RebelLogo from '../images/rebel-logo.png';
import RebelBook1 from '../images/Rebel-Book1.jpg';
import RebelBook2 from '../images/Rebel-Book2.jpg';
import RebelBook3 from '../images/Rebel-Book3.jpg';
import RebelMav from '../images/Rebel-Bronze.jpg';
import RebelSilver from '../images/Rebel-Silver.jpg';
import RebelCup from '../images/rebelcup.png';
import RebelBadge from '../images/rebelbadge.png';
import UltimateRebel23 from '../images/UltimateRebel2023.png';
import UltimateRebel24 from '../images/UltimateRebel2024.png';
import UltimateRebel25 from '../images/UltimateRebel2025.png';

const Home = () => {
    return (
        <div>
            <div className="row">
                <div className="rebelLeft"><img src={RebelLogo} alt="Sticker of the word rebel" className="rebelLogoLeft" /></div>
                <div className="rebelMid">
                    <h2>Rebel Badges</h2>
                </div>
                <div className="rebelRight"><img src={RebelLogo} alt="Sticker of the word rebel" className="rebelLogoRight" /></div>
            </div>
            <div className="row">
                <div className="rebelCard rebelCardBook">
                    <Link to='./bookone'>
                        <div className="rebelCardTitle"><h3>Rebel Badge Book 1</h3></div>
                        <div className="rebelCardImage"><img src={RebelBook1} alt="The Rebel Badge Book - Book 1" /></div>
                    </Link>
                </div>
                <div className="rebelCard rebelCardBook">
                    <Link to='./booktwo'>
                        <div className="rebelCardTitle"><h3>Rebel Badge Book 2</h3></div>
                        <div className="rebelCardImage"><img src={RebelBook2} alt="The Rebel Badge Book - Book 2" /></div>
                    </Link>
                </div>
                <div className="rebelCard rebelCardBook">
                    <Link to='./bookthree'>
                        <div className="rebelCardTitle"><h3>Rebel Badge Book 3</h3></div>
                        <div className="rebelCardImage"><img src={RebelBook3} alt="The Rebel Badge Book - Book 3" /></div>
                    </Link>
                </div>
                <div className="rebelCard rebelCardBook">
                    <Link to='./bronze'>
                        <div className="rebelCardTitle"><h3>Maverick Bronze</h3></div>
                        <div className="rebelCardImage"><img src={RebelMav} alt="The Maverick Awards - Bronze Log Book" /></div>
                    </Link>
                </div>
                <div className="rebelCard rebelCardBook">
                    <Link to='./silver'>
                        <div className="rebelCardTitle"><h3>Maverick Silver</h3></div>
                        <div className="rebelCardImage"><img src={RebelSilver} alt="The Maverick Awards - Silver Log Book" /></div>
                    </Link>
                </div>
                 <div className="rebelCard rebelCardFull patch">
                    <div className="rebelCardPatchTitle"><h3>Ultimate Rebel</h3>
                        This chart shows my progress towards earning my Rebel section patches and Ultimate Rebel patch. Each colour shows the total number of badges needed to get the patch and how many I have completed.
                        <br/><br/>
                        In 2023, I achieved Ultimate Rebel!! I might be able to do it again in 2024, I'm still tracking progress as I can definitely at least get a couple of section patches.
                        <br/><br/>
                        <div className="patchRow">
                            <div className="patchItem"><div className="square purple-light"></div>Creative Rebel</div>
                            <div className="patchItem"><div className="square blue-light"></div>Global Rebel</div>
                            <div className="patchItem"><div className="square olive-light"></div>Grown Up Rebel</div>
                            <div className="patchItem"><div className="square pink-light"></div>Self Aware Rebel</div>
                            <div className="patchItem"><div className="square green-light"></div>Wellness Rebel</div>
                            <div className="patchItem"><div className="square brown-light"></div>Wild Rebel</div>
                        </div>
                        <br/>
                    </div>
                    <div className="patchRow">
                        <div className="rebelChartImage">
                            <img className="Chart" src={UltimateRebel25} alt="Pie chart showing progress against patch targets" />
                        </div>
                        <div className="rebelChartImage">
                            <img className="Chart" src={UltimateRebel24} alt="Pie chart showing progress against patch targets" />
                        </div>
                        <div className="rebelChartImage">
                            <img className="Chart" src={UltimateRebel23} alt="Pie chart showing progress against patch targets" />
                        </div>
                    </div>
                </div>
                <div className="rebelCard rebelCardFull patch">
                    <div className="rebelCardPatchTitle"><h3>Cup Points</h3>
                        This section shows the total number of cup points I have earned for Team Nelson so far. I managed to get over the 200 I wanted in 2023, let's see how 2024 goes!<br/><br/>
                    </div>
                    <div className="rebelCardRow">
                        <div className="rebelCardStats-Full"><div className="statsText">Running Total</div><div className="statsNumber">604</div></div>
                    </div> 
                    <div className="rebelCardRow">
                        <div className="rebelCardStats-Full"><div className="statsText statsTextDark">2025 Total</div><div className="statsNumber">0</div></div>
                        <div className='rebelCardStats'><div className="statsText statsTextDark">Community Points:</div> <div className="statsNumber">0</div></div>
                        <div className='rebelCardStats'><div className="statsText statsTextDark">Bonus Points:</div><div className="statsNumber">0</div></div>
                        <div className='rebelCardStats'><div className="statsText statsTextDark">Maverick Points:</div> <div className="statsNumber">0</div></div>
                    </div>    
                    <div className="rebelCardRow">
                        <div className="rebelCardStats-Full"><div className="statsText statsText">2024 Total</div><div className="statsNumber">151</div></div>
                        <div className='rebelCardStats'><div className="statsText statsText">Community Points:</div> <div className="statsNumber">119</div></div>
                        <div className='rebelCardStats'><div className="statsText statsText">Bonus Points:</div><div className="statsNumber">32</div></div>
                        <div className='rebelCardStats'><div className="statsText statsText">Maverick Points:</div> <div className="statsNumber">0</div></div>
                    </div>    
                    <div className="rebelCardRow">
                        <div className="rebelCardStats-Full"><div className="statsText">2023 Total</div><div className="statsNumber">213</div></div>
                        <div className='rebelCardStats'><div className="statsText">Community Points:</div> <div className="statsNumber">164</div></div>
                        <div className='rebelCardStats'><div className="statsText">Bonus Points:</div><div className="statsNumber">34</div></div>
                        <div className='rebelCardStats'><div className="statsText">Maverick Points:</div> <div className="statsNumber">15</div></div>
                    </div>                      
                </div>
                <div className="rebelCard rebelCardFull">
                    <Link to='./extras'>
                        <div className="rebelCardTitle"><h3>Extra Challenges - Monthly, Quarterly, Annual and The Rebel Cup</h3><br/><br/></div>
                        <div className="rebelCardImage">
                            <img className="imgThird" src={RebelBadge} alt="Illustration of a gold cup" />
                            <img className="imgThird" src={RebelCup} alt="Illustration of a gold cup" />
                            <img className="imgThird" src={RebelBadge} alt="Illustration of a gold cup" />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Home