import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const AdvancedApothecary = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Advanced Apothecary"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 1</div>
                <div className="badgePageItemInfo green-light">Read</div>
                <div className="badgePageItemInfo hidden">Read a book about the medicinal uses of plants</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 2</div>
                <div className="badgePageItemInfo green-light">Waters</div>
                <div className="badgePageItemInfo hidden">Learn how to make floral waters and hydrosols. What are their benefits & Uses?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 3</div>
                <div className="badgePageItemInfo green-light">Oils</div>
                <div className="badgePageItemInfo hidden">Learn how to make infused oils, and what you can infuse. What are their benefits & uses?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 4</div>
                <div className="badgePageItemInfo green-light">Grow</div>
                <div className="badgePageItemInfo hidden">Grow at least 3 different plants which have medicinal properties.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 5</div>
                <div className="badgePageItemInfo green-light">Cleaning</div>
                <div className="badgePageItemInfo hidden">Create at least one natural home cleaning product</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 6</div>
                <div className="badgePageItemInfo green-light">Soap</div>
                <div className="badgePageItemInfo hidden">Create a lotion, soap or balm using fresh herbs or plants</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 7</div>
                <div className="badgePageItemInfo green-light">Homemade</div>
                <div className="badgePageItemInfo hidden">Make a basket of at least five different homemade spa goodies to either use yourself or gift to someone. Choose 
                different recipes to the ones used for the apothecary badge.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default AdvancedApothecary