import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const AdvancedArtist = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Advanced Artist"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Project 1</div>
                <div className="badgePageItemInfo purple-light">Landscape in Colour</div>
                <div className="badgePageItemInfo hidden">Landscape, 0.75m2 at least, colour</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Project 2</div>
                <div className="badgePageItemInfo purple-light">Portrait in Graphite</div>
                <div className="badgePageItemInfo hidden">Portrait, 0.75m2 at least, graphite</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Project 3</div>
                <div className="badgePageItemInfo purple-light">Abstract, Medium TBC</div>
                <div className="badgePageItemInfo hidden">Abstract, 0.75m2 at least, medium TBC</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default AdvancedArtist