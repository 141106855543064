import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Mythology = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Mythology"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 1</div>
                <div className="badgePageItemInfo blue-light">Myths, Legends and Fables</div>
                <div className="badgePageItemInfo hidden">Understand the difference between a myth, a legend and a fable. Spend at least three months reading different myths
                from around the world. Cover all the populated continents, your own area and a range of different ancient civilisations.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 2</div>
                <div className="badgePageItemInfo blue-light">Cultures</div>
                <div className="badgePageItemInfo hidden">Choose at least three different cultures or time periods, and draw parallels between the stories. Are there
                common elements or themes which appear? Write your own myth, including some of these elements.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 3</div>
                <div className="badgePageItemInfo blue-light">Myth</div>
                <div className="badgePageItemInfo hidden">Write and illustrate your version of a favourite myth or retell a myth verbally to someone who has never heard it before</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 4</div>
                <div className="badgePageItemInfo blue-light">Cryptozoology</div>
                <div className="badgePageItemInfo hidden">Learn about Cryptozoology. What is the difference between a cryptid and a mythical creature? Research the origins and
                stories surrounding at least five different cryptids and mythical beasts. Are there parallels between certain cryptids?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 5</div>
                <div className="badgePageItemInfo blue-light">Invent</div>
                <div className="badgePageItemInfo hidden">Invent your own mythical creature. Either drawm paint or make a 3D model of your creature. Think about what it
                eats, where it lives, its temperament and its behavioural patterns.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 6</div>
                <div className="badgePageItemInfo blue-light">Modern Retellings</div>
                <div className="badgePageItemInfo hidden">Find two modern retellings of the same myth - eg a TV show, film, play or book. After watching or reading, read the 
                original source material and identify the similarities ad differences in the retellings.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>    
        </div>
    )
}

export default Mythology