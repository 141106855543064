import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const CurrentAffairs = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Current Affairs"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 1</div>
                <div className="badgePageItemInfo blue-light">Be Aware</div>
                <div className="badgePageItemInfo hidden">Over a period of 3 mths make an active effort to stay on top of the news - reading newspapers, watching daily news, listening to radio</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 2</div>
                <div className="badgePageItemInfo blue-light">Spectrum</div>
                <div className="badgePageItemInfo hidden">Create a visual political spectrum from far-right to far-left. Where do different newspapers, TV Stations, radio Stations and podcasts sit?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 3</div>
                <div className="badgePageItemInfo blue-light">Story Presentation</div>
                <div className="badgePageItemInfo hidden">Choose a current domestic news story and compare the different ways the story is presented in different forms of media. Where can you find the
                most independent reporting of the story? Now look at the way the story was reported abroad. Does the foreign media coverage change your own interpreation of the story?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 4</div>
                <div className="badgePageItemInfo blue-light">Bulletins</div>
                <div className="badgePageItemInfo hidden">Watch a daily news bulleting (half hour min) regularly for at least two weeks. Categorise the types of stories shown and keep
                a tally of which types of news stories get the most coverage. Do you think this is the right balance? What would you change?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 5</div>
                <div className="badgePageItemInfo blue-light">Fake News</div>
                <div className="badgePageItemInfo hidden">Find some examples of fake news stories that you are surrounded by on social media. How can you differentiate from fake and real news?
                Learn how to carry out a CRAAP test. How has fake news affected elections and other significant historic events in recent years? How do AI and bots increase its distribution?</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 6</div>
                <div className="badgePageItemInfo blue-light">Timeline</div>
                <div className="badgePageItemInfo hidden">Create a timeline of events which have caused distrust in the media over the last century, inc examples of propaganda, and scandals
                such as the News of the World phone hacking</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 7</div>
                <div className="badgePageItemInfo blue-light">Law</div>
                <div className="badgePageItemInfo hidden">Learn about the media laws and regulations in your country. What can and can't journalists do? In particular look at privacy,
                defamation, libel, court reporting and contempt of court. Understand how these laws also affect social media users.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
        </div>
    )
}

export default CurrentAffairs