import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const MyGoals = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"My Goals"} />
            <p className="goalsIntro">I was doing a monthly update for each item but it was getting very repetitive, so I've done a summary for each that applies across the year!</p>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Health</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Fitness</strong></p>
                        <ul className="list">
                            <li>500 mile walk - 12 months</li>
                            <li>Earth Runs 1% Club - 12 months</li>
                            <li>Step up to an extra challenge each quarter (at least)</li>
                        </ul>
                        <br/>
                        <p><strong>2. Diet</strong></p>
                        <ul className="list">
                            <li>Maintain 16-8 Fast (at least 12 hour fast) at least 5 days a week</li>
                            <li>Maintain Low Carb &lt;30g at least 5 days a week</li>
                            <li>Cook from scratch at least 5 days a week</li>
                        </ul>
                        <br/>
                        <p><strong>Update:</strong></p>
                        <p>Completed the Big Rebel Adventure by walking 26 miles and climbing 3000 metres. Earned 3 medals from MedalMad: Under the Sea, Viking, Las Vegas.</p>
                        <p>Was active for 331 days out of 365 planting 336 trees with Earth Runs 1% Club.</p>
                        <p>Completed the RNL May Day Mile.</p>
                        <p>Achieved walking challenges by walking one mile a day indoors with extra on the weekends while beach cleaning.</p>
                        <p>Introduced a Boxing Machine for extra exercise later in the year.</p>
                        <p>Maintained at least 12 hour fast for 5 days a week.</p>
                        <p>Increased water intake.</p>
                        <p>Maintained low carb diet at least 5 days a week.</p>
                        <p>Meal planned and cooked from scratch at least 5 days a week for the majority of the year, I did change up my diet towards the end of the year, going back to a super low carb regime.</p>
                        <br/>                        
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Career</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Continuous Learning</strong></p>
                        <ul className="list">
                            <li>Always use monthly learning day</li>
                            <li>Keep up with news and trends</li>
                            <li>Work on modernisation program, improving skills in new technologies</li>
                        </ul>
                        <br/>
                        
                        <p><strong>Update:</strong></p>
                        <p>Learning day taken as scheduled</p>
                        <p>Using Daily.Dev daily and TLDR newsletter at least weekly to keep up with trends</p>
                        <p>Modernisation program on hold, but new tech being used for new projects</p>
                        <p>Implementing new tech and standards in new projects</p>
                        <p>Learned how to implement canvas in new projects.</p>
                        <p>Completed 365 Days of Creativity graphic design course, incuding 12 assignments.</p>
                        <br/>
                        <p>List of training done:</p>
                        <ul className="list">
                            <li>365 days of Creativity</li>
                            <li>Personal Branding Beginners Course</li>
                            <li>Blogging Insight to Learn How to Write Better Blog Posts</li>
                            <li>The Complete Law of Attraction</li>
                            <li>Audio Engineering Basics</li>
                            <li>Master the Art of Reading People</li>
                            <li>Video Editing for Beginners</li>
                            <li>Mobile Prouct Photography and ChatGPT</li>
                            <li>Personal Branding Mastery and How to Start an Online Business</li>
                            <li>The Complete OPENAI JS AIs Couse</li>
                            <li>Master Laravel 11 and Vue 3</li>
                            <li>Introduction to Stable Diffusion for Developers</li>
                            <li>Write Dreamy and Imaginative Poetry</li>
                            <li>Songwriting: Creative Lyric Writing Approaches</li>
                            <li>Secret Sauce of Great Writing</li>
                            <li>Next Level CSS Creative Hover and Animation Effects</li>
                            <li>Introduction to Graphic Design</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Wealth</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Savings</strong></p>
                        <ul className="list">
                            <li>Put dedicated money into savings each month</li>
                        </ul>
                        <br/>
                        <p><strong>2. Side Hustles</strong></p>
                        <ul className="list">
                            <li>Work on small businesses to turn profit</li>
                            <li>Rebrand Gifted Cards, write strategy</li>
                            <li>Moments and Moxie, write strategy</li>
                            <li>investigate other options</li>
                        </ul>
                        <br/>
                        <p><strong>Update:</strong></p>
                        <p>Savings budgeted and set aside.</p>
                        <p>Gifted cards rebrand done, new NuMonday store created and sale made. Deactivated for new stock prep. Strategy in progress.</p>
                        <p>Moments and Moxie still running. Experimented with mockups for marketing, strategy in progress.</p>
                        <p>Investigated some options for passive income, not yet implemented.</p>
                        
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Travel</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Travel</strong></p>
                        <ul className="list">
                            <li>Continue with family holidays</li>
                            <li>3 nights away for Silver Maverick</li>
                        </ul>
                        <br/>
                        <p><strong>Update:</strong></p>
                        <p>3 nights Glamping at West Bay completed for Silver Maverick.</p>
                        <p>Family holiday - 1 week in South Wales</p>
                        <p>Week away in Cornwall with the parents</p>
                        <p>I don't travel and have no desire to do so, the family trips are my only travel desires.</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Family</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Family</strong></p>
                        <ul className="list">
                            <li>Continue to see family as much as possible</li>
                        </ul>
                        <br/>
                        <p><strong>Update:</strong></p>
                        <p>No goal required, already maximised. I have seen plenty of the famliy this year which has been amazing.</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Lifestyle</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. RBC</strong></p>
                        <ul className="list">
                            <li>Be more interactive in RBC online</li>
                            <li>Complete all planned badges</li>
                        </ul>
                        <br/>
                        <p><strong>2. Environment</strong></p>
                        <ul className="list">
                            <li>Set up CMARC events</li>
                            <li>Set up PFH Activities</li>
                        </ul>
                        <br/>
                        <p><strong>Update:</strong></p>
                        <p>Trying hard to be a more active rebel and I'm on schedule with my planned badges! I was trying to be active in my local rebellion, but there wasn't any uptake on virtual or physical events - with no one turning up at the Big Rebel Spring Clean I organised - so I lost motivation.</p>
                        <p>CMARC events were done, Egg Case Hunt, Seaweed Search, Microplastics Survey</p>
                        <p>PFH Activities weren't done this year.</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Creativity</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Online</strong></p>
                        <ul className="list">
                            <li>Continue 365 days of Creativity course</li>
                            <li>Keep up to date with digital design</li>
                        </ul>
                        <br/>
                        <p><strong>2. Offline</strong></p>
                        <ul className="list">
                            <li>Do more drawing</li>
                            <li>Develop products for GC</li>
                        </ul>
                        <br/>
                        <p><strong>Update:</strong></p>
                        <p>Completed 365 days course - one module per day as required</p>
                        <p>Produced several artworks, both rebel related and not. Worked in Graphite and Colour.</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Knowledge</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Training</strong></p>
                        <ul className="list">
                            <li>Do at least one training course per month</li>
                        </ul>
                        <br/>
                        <p><strong>2. Learning</strong></p>
                        <ul className="list">
                            <li>Read more non-fiction</li>
                            <li>Listen to more podcasts</li>
                            <li>Watch more documentaries</li>
                        </ul>
                        <br/>
                        <p><strong>Update:</strong></p>
                        <p>See Continuous Learning above for list of courses done.</p>
                        <p>Started Duolingo with French, Spanish, Swedish and Hawaiian.</p>
                        <p>Completed an introductory Swedish course.</p>
                        <p>Completed 52 books in a year challenge with both fiction and non-fiction books.</p>
                        <p>I haven't listened to a lot of podcasts, but I have listened to some episodes of various shows through the year.</p>
                        <p>I haven't watched a lot of documentaries as I have reduced my TV hours, but I have watched some throughout the year.</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Relationships</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Relationships</strong></p>
                        <ul className="list">
                            <li>Make sure to check in on the one remaining friend</li>
                        </ul>
                        <br/>
                        <p><strong>Update:</strong></p>
                        <p>I don't have relationships beyond my immediate family.</p>
                        <p>I have been in touch with my one remaining friend consistently throughout the year, meeting up occassionally.</p>
                        <p>Arguably I have work relationships, these are all good to the best of my knowledge. I work hard to keep a good reputation amongst colleagues.</p>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyGoals