import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const AdvancedDetective = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Advanced Detective"} area={"bookthree"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Case 1</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden">solve at least 12 different cases. Each case should take at least an hour to solve and include evidence, witness statements
                and other features of a real life case, such as forensics. Murder Mysteries, puzzle boxes, cases by post, escape rooms, books, podcasts etc.</div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Case 2</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Case 3</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Case 4</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Case 5</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Case 6</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Case 7</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Case 8</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Case 9</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Case 10</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Case 11</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Case 12</div>
                <div className="badgePageItemInfo olive-light"></div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    
                </div>
            </div> 
        </div>
    )
}

export default AdvancedDetective