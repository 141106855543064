import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Singer = () => {
    return (
        <div>
            <ActionBar area={"bookthree"} />
            <EvidenceHeader title={"Singer"} area={"bookthree"} />
            <p className="singerIntro">This badge is backdated. I have been singing since I was a child, both solo and with choirs or theatre groups and only stopped recently, so I am claiming this as completed without any further action.</p>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 1</div>
                 <div className="badgePageItemInfo purple-light">Technique</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    I have studied technique at length and with private lessons and practiced over many years.
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 2</div>
                 <div className="badgePageItemInfo purple-light">Group</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    I have performed with choirs and theatre groups for many years with regular performances and rehearsals.
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 3</div>
                 <div className="badgePageItemInfo purple-light">Sight Reading</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    This is something that I have done multiple times over the years.
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 4</div>
                 <div className="badgePageItemInfo purple-light">Showcase</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    I have been involved in many performances and competitions over the years, both solo and as part of a choir or theatre group.
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 5</div>
                 <div className="badgePageItemInfo purple-light">Outlet</div>
                <div className="badgePageItemInfo hidden"></div>
                <div className="badgePageItemContent">
                    I have been a part of choirs and theatre groups for many years. We have raised thousands of pounds for various charities as part of that and also performed in care homes and hotels to entertain residents.
                </div>
            </div>
            
        </div>
    )
}

export default Singer